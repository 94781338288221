<template>
  <div>
    <img
      class="avatar"
      :src="avatar"
      :alt="fullName"
      :width="size"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import gravatarUrl from 'gravatar-url';

export default {
  name: 'Avatar',
  props: {
    size: {
      type: Number,
      default: 30,
    },
  },
  computed: {
    ...mapGetters('user', [
      'user',
      'fullName',
    ]),
    avatar() {
      // detect if there is an image for the email
      // and display avatar with initials if there's not
      const gravURL = gravatarUrl(this.user.email, {
        size: 200,
        default: 'identicon',
      });
      return gravURL;
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar {
  vertical-align: middle;
  border-radius: 50%;
}
</style>
